<template>
  <div class="privateContent">
    <!--    排行榜-->
    <div class="pxbs">
      <h1>官方榜</h1>
      <div class="pxb"
           v-for="(item, index) in TopList"
           :key="index">
        <span @click="toPlaylistDetail (item.id)">
          <i :style="{ position: 'absolute'}"
             class="iconfont play_icon icon-bofang"></i>
          <img v-lazy="item.coverImgUrl">
        </span>
        <ul>
          <li v-for="(songItem, index) in NavTopList[index]"
              :key="index"
              :class="{ bgcolor: index % 2 !== 0 }">
            <span :style="{ margin: '0 1vw'}"
                  :class="{ item_active: index + 1 < 4 }"> {{ index + 1 }} </span>
            <span style="cursor: pointer;"
                  @click="play(songItem)"> {{ songItem.name }} </span>
            <span :style="{ color: 'gray', float: 'right', marginRight: '5px'}"
                  v-for="(authors, index) in songItem.ar"
                  :key="index"
                  style="cursor: pointer"
                  @click="singerDetailJump(songItem.ar[0].id)">
              {{ authors.name }}
            </span>
          </li>
        </ul>
      </div>

    </div>
    <!--  歌手榜 -->
    <div class="pxb">
      <span @click="$router.push('/singer')">
        <i class="image_bg"></i>
        <span class="text_bg">歌手榜</span>
        <img src="https://p2.music.126.net/rYYhHXZHwCfizE0N46F37Q==/109951166115911716.jpg">
      </span>
      <ul>
        <li @click="singerClick(singerItem)"
            v-for="(singerItem, index) in singer"
            :key="index">
          <span :style="{ margin: '0 5px'}"
                :class="{ item_active: index + 1 < 4 }"> {{ index + 1 }} </span>
          <span style=" cursor: pointer"> {{ singerItem.name }} </span>
        </li>
      </ul>
    </div>
    <!--  全球榜-->
    <div class="global">
      <div class="globalItme"
           @click="toPlaylistDetail(item.id)"
           v-for="(item, index) in globalList"
           :key="index">
        <div class="img-wrap">
          <img v-lazy="item.coverImgUrl" />
          <i class="iconfont playCOunt icon-bofang"
             style="cursor: pointer;"></i>
          <span class="playcount">
            <i class="iconfont icon-caret-right"></i>
            {{ item.playCount | ellipsisPlayVolume }}
          </span>
        </div>
        <p>{{ item.name }}</p>
      </div>
    </div>
  </div>

</template>

<script>
import { topListDetailAPI, playlistDetailAPI, topSingerAPI } from '@/api/api'
export default {
  data () {
    return {
      TopList: [], // 歌曲排行榜
      NavTopList: [], // 前几排行榜
      globalList: [], // 全球榜
      singer: [] // 歌手榜
    }
  },
  computed: {
    musicQueue () {
      return this.$store.state.musicQueue
    }
  },
  created () {
    this.getLeaderBoard()
  },
  methods: {
    async getLeaderBoard () {
      const { data: res } = await topListDetailAPI()
      this.TopList = res.list.slice(0, 4)
      if (document.documentElement.clientWidth < 600) {
        this.globalList = res.list.slice(4, 16)
      } else {
        this.globalList = res.list.slice(4, res.list.length)
      }
      this.TopList.map(async (item) => {
        const {
          data: { playlist: res }
        } = await playlistDetailAPI({ id: item.id })
        this.NavTopList.push(res.tracks.slice(0, 5))
      })
      const { data: resSinger } = await topSingerAPI()
      this.singer = resSinger.list.artists.slice(0, 5)
    },
    // 进入歌单详情
    toPlaylistDetail (id) {
      this.$router.push(`/playlist?id=${id}`)
    },
    // 点击歌手进入歌手详情
    singerDetailJump (id) {
      this.$router.push(`/singerDetail?id=${id}`)
    },
    play (item) {
      const duration = this.$options.filters.formatDuration(item.dt)
      const musicInfo = {
        imgUrl: item.al.picUrl,
        artistInfo: item.ar,
        songName: item.name,
        id: item.id,
        duration
      }
      this.playMusic(item, musicInfo)
    }
  }
}
</script>

<style scoped>
h1 {
  margin: 30px 0 20px 50px;
  font-size: 26px;
}

.pxb {
  padding-left: 50px;
  max-height: 200px;
  position: relative;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin: 30px 0;
}
.pxb img {
  border-radius: 20px;
  height: 200px;
  cursor: pointer;
}
.pxb ul {
  display: inline-block;
  margin-left: 20px;
  width: 60%;
  height: 100%;
}
.pxb ul li {
  width: 100%;
  height: 40px;
  line-height: 40px;
}
.pxb ul li:hover {
  background: rgba(159, 159, 159, 0.2);
}
.play_icon {
  position: absolute;
  top: 80px;
  left: 130px;
  color: #ff0000;
  display: none;
  cursor: pointer;
}
.pxb span:hover .play_icon {
  display: block !important;
}
/*
 排行榜end
 */
.globalItme {
  position: relative;
  top: 0;
  left: 0;
  display: inline-block;
  margin: 10px 25px;
}
.globalItme .img-wrap img {
  cursor: pointer;
  width: 200px;
  border-radius: 20px;
}
.globalItme span {
  cursor: pointer;
  margin: 10px 10px 0 0;
}
.globalItme .img-wrap .playcount {
  position: absolute;
  top: 0;
  right: 5px;
  color: #ffffff;
}
.globalItme .img-wrap .playCOunt {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ff0000;
  display: none;
}
.globalItme .img-wrap:hover .playCOunt {
  display: block;
}
.image_bg {
  position: absolute;
  z-index: 9;
  text-align: center;
  width: 200px;
  border-radius: 20px;
  height: 200px;
  display: block;
  cursor: pointer;
  background: rgba(128, 0, 128, 0.5);
}
.globalItme p {
  margin-top: 1vw;
  margin-left: 1vw;
  height: 2vw;
}
.text_bg {
  position: absolute;
  top: 80px;
  left: 100px;
  font-size: 30px;
  color: #ffffff;
  z-index: 999;
  cursor: pointer;
}
.item_active {
  color: #ff0000;
}
.bgcolor {
  background-color: #f8f8f8;
}
@media screen and (max-width: 479px) {
  h1 {
    margin: 4vw 0 5vw 2vw;
    font-size: 18px;
  }
  .pxb {
    margin-top: 0;
    padding: 0;
  }
  .pxb img {
    height: 20vw;
  }
  .pxb ul {
    margin-left: 2vw;
    width: 60vw;
  }
  .pxb ul li {
    height: 7vw;
    font-size: 14px;
    line-height: 7vw;
  }
  .image_bg {
    height: 20vw;
    width: 20vw;
  }
  .text_bg {
    top: 15vw;
    left: 4.5vw;
    font-size: 16px;
  }
  .globalItme {
    width: 24vw;
    margin: 0;
    margin-left: 2vw;
    height: 30vw;
    margin-bottom: 2vw;
  }
  .globalItme .img-wrap img {
    cursor: pointer;
    width: 90%;
    border-radius: 20px;
  }
  .globalItme p {
    font-size: 10px;
    height: 7vw;
    margin-left: 1vw;
    overflow: hidden;
  }
  .globalItme .img-wrap .playcount,
  .globalItme .img-wrap:hover .playCOunt {
    display: none;
  }
}
</style>
